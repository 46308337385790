import React from "react";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
export default function Sectheree() {
  return (
    <>
      <Container fluid className="__bg-primary-sl-mirror">
        <Container>
          <Row className="fade-in">
            <Col lg={12}>
              <div className="__introduction">
                <p className="text-center">Introducing</p>
              </div>
            </Col>
            <Col lg={12}>
              <div className="__image_logo-sl">
                <img
                  src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BCM%5D-Logo.png"
                  alt="logo"
                  className="img img-fluid lazyload"
                />
              </div>
            </Col>
          </Row>
          <Row className="fade-in __margin-top-sl-50">
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-white text-center">
                  Ketika Join Kelas Ini, Kamu Akan Dapatkan :
                </h2>
              </div>
            </Col>
          </Row>

          <Accordion defaultActiveKey="0">
            <Card>
              {/* <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                    <h2 className="__text-headline-class">
                        VIP CLASS Commitment Mastery Online Training
                    </h2>
                    </Accordion.Toggle>
                </Card.Header> */}
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _a">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BMockup%5D-CM.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Mengerti bagaimana cara agar pria mau komit sama
                            kamu
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari cara yang salah dan malah jadi bubar
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari kehidupan romansa yang tak tentu
                            arah, tanpa kepastian
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Tahu kapan waktu yang tepat untuk berbicara tentang
                            komitmen
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Menjadi wanita cerdas yang tidak dipermainkan cowok
                            modus
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  {/* <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _b">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BWorkBook%5D-CM.png"
                            alt="img class cm"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            ​ Berisi rangkuman materi yang memudahkanmu tidak
                            perlu repot-repot membuat rangkuman sendiri
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _c">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BVIsualCheatSheet%5D-CM.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi contekan visual yang memudahkanmu memahami
                            isi materi.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _d">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BAudioRecording%5D-CM.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6} className="__margin-top-class-singel">
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel pt-1">
                            Akses audio rekaman selama 30 hari
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Kamu bisa mengulang kembali materi dalam bentuk
                            audio.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _e">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BCoachingSession%5D-CM.png"
                            alt="img class cm"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex mt-3">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl _font-size-singel">
                            Sesi khusus 60 Menit bersama Coach Jose Aditya
                            dimana kamu dan peserta VIP lainnya mendapat
                            privilege Q & A mengenai materi yang tidak didapat
                            di kelas reguler.
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row> */}
                </Card.Body>
              </Accordion.Collapse>
            </Card>

            {/* <Card className="mt-2">
              <Card.Header>
                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                  <h2 className="__text-headline-class">
                    Regular CLASS Commitment Mastery Online Training
                  </h2>
                </Accordion.Toggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BMockup%5D-CM.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Mengerti bagaimana cara agar pria mau komit sama
                            kamu
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari cara yang salah dan malah jadi bubar
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Terhindar dari kehidupan romansa yang tak tentu
                            arah, tanpa kepastian
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Tahu kapan waktu yang tepat untuk berbicara tentang
                            komitmen
                          </p>
                        </div>
                        <div className="d-flex">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl">
                            Menjadi wanita cerdas yang tidak dipermainkan cowok
                            modus
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>

                  <Row className="__width-container bg-white rounded">
                    <Row className="__noactive-margin">
                      <Col lg={6}>
                        <div className="__img-class-join-sl _b">
                          <img
                            src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/commitment-mastery/%5BWorkBook%5D-CM.png"
                            alt="img class attraction mastery"
                            className="img img-fluid lazyload"
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="d-flex __margin-top-class-singel">
                          <div className="__width-icon-and-text pt-2">
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              className="__fa-check-circle-sl"
                              style={{ color: "green" }}
                            />
                          </div>
                          <p className="__text-desc-icon-sl __font-size-singel">
                            Berisi rangkuman materi yang memudahkanmu tidak
                            perlu repot-repot membuat rangkuman sendiri
                          </p>
                        </div>
                      </Col>
                    </Row>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card> */}
          </Accordion>
        </Container>
      </Container>
    </>
  );
}
